
import React, { useState, useEffect } from 'react';
import verveLogo from './asset/company_logo_header.png'
import HomePage from './pages/home'
import Asthetic from './pages/aesthetic/aesthetic'
import AboutUsPage from './pages/aboutUs/aboutUs'
import AntiAging from './pages/antiAging/antiAging'
import MedDevice from './pages/medDevice/medDevice'
import { BrowserRouter as Router, useRoutes, Link } from "react-router-dom";
import { Twirl as Hamburger } from 'hamburger-react'
import './App.css';


const GetPage = () => {
  let routes = useRoutes([
    { path: "/", element: < HomePage /> },
    { path: "/asthetic", element: < Asthetic /> },
    { path: "/anti-aging", element: < AntiAging /> },
    { path: "/medical-device", element: < MedDevice /> },
    { path: "/about-us", element: < AboutUsPage /> }
    // ...
  ]);
  return routes
}


const ShowNavPlane = (isOpen, setOpen) => {

  if (isOpen) {
    return (
      <nav id="slider" className="slide-in">
        <ul className="navSide-list">
          <li onClick={() => { setOpen(false) }}><Link to="/asthetic">Aesthetic</Link></li>
          <li onClick={() => { setOpen(false) }}><Link to="/anti-aging">Anti Aging</Link></li>
          <li onClick={() => { setOpen(false) }}><Link to="/medical-device">Medical Device</Link></li>
          <li onClick={() => { setOpen(false) }}><Link to="/about-us">About Us</Link></li>
        </ul >
      </nav >
    )
  } else {
    return (
      <nav id="slider" className="slide-out">
        <ul className="navSide-list">
          <li ><Link to="/asthetic">Aesthetic</Link></li>
          <li><Link to="/anti-aging">Anti Aging</Link></li>
          <li><Link to="/medical-device">Medical Device</Link></li>
          <li><Link to="/about-us">About Us</Link></li>
        </ul >
      </nav >

    )
  }

}


const App = () => {
  const [isOpen, setOpen] = useState(false)

  return (
    <div className='App'>
      <div id="slider-wrapper" >
        <nav className="navbar background">
          <div className="logotNav">
            < Link to="/" onClick={() => { setOpen(false) }}><img src={verveLogo} className="headerLogo" alt="logo" /></Link>
          </div>
          <ul className="nav-list">
            <Hamburger toggled={isOpen} toggle={setOpen} />
            <li><Link to="/asthetic">Aesthetic</Link></li>
            <li><Link to="/anti-aging">Anti Aging</Link></li>
            <li><Link to="/medical-device">Medical Device</Link></li>
            <li><Link to="/about-us">About Us</Link></li>
          </ul>
        </nav>
        {ShowNavPlane(isOpen, setOpen)}
      </div>
      <GetPage />

    </div >
  )
}

export default App

import './antiAging.css'
import '../home.css'
import React, { useState } from "react";
import DrMed from '../../asset/dr-martin.png'
import DrMedLogo from '../../asset/dr-med-logo.svg'
import hotelLogo from '../../asset/bachmair-weissach-logo.svg'
import hotelVdo from '../../asset/BACHMAIR_WEISSACH_01-12-22.mp4'
import cover from '../../asset/anti-aging-band.png'
import Footer from '../footer'

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="text">
            {isReadMore ? text.slice(0, 150) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? "...read more" : " show less"}
            </span>
        </p>
    );
};

const FisrtSection = () => {
    return (<div className='ag-container'>
        <div className='ag-sub-container'>
            <img src={DrMedLogo} alt="dr. nicolas martin company" />
        </div>
        <div className='ag-sub-container'>
            <p >Thank you very much for your interest in the Four Seasons Medical
                Center and Clinic (FSC) located at the landmark Hotel Bachmair Weissach in the
                Bavarian Alps in Rottach-Egern, Germany.
            </p>
            <p>
                For nearly five decades the Medical Center and Clinic directed under joint
                stewardship of Dr. med. Claus Martin and his son Dr. med. Nicolas Martin, has
                been one of the leading institutes in the world of Regenerative Medicine using
                Live-Cell-Therapy (LCT).
            </p>
        </div>
        <div className='ag-sub-container'>
            <p >Since the inauguration of Cell-Therapy by Prof. Dr. med. P. Niehans
                more than 90 years ago this method has continuously been improved and refined.
                During the last 40 years a lot has been contributed to this technology by
                Dres. C. and N. Martin in order to counteract ageing and to alleviate the symptoms
                of chronic illnesses.
            </p>
            <p >
                Always in search of new alternative revitalization methods to prevent and
                alleviate ageing and symptoms of chronic diseases we have now turned to the for
                decades well known, successful biomolecular therapy resçarch of
                Prof. Dr. med. Karl E. Theurer with cytoplasmatic organ extracts, which can be
                used in low-dosage and because of its special manufacturing process.

            </p><br></br>
            <p>This improved new therapy concept with “low-dose Cell-Extracts" is the
                new Live Cell Therapy (nLCT) and can be considered as the next generation of
                our well known revitalization programme.

                It should also be mentioned, that in recent years the use of low-dose
                biological molecules to improve cell- and tissue-regeneration has turned out more
                and more trend setting in medical drug research and applied treatments.

                The new Cell Extracts are also obtained from organs and tissues of cattle
                and sheep embryos or newly born.

                The active ingredients contained in the "new Cell-Extracts" are bioactive
                molecules such as peptides, enzymes and so called growth factors, which are tried
                and tested anti-ageing agents and help alleviate symptoms of chronic diseases by
                strengthening the body's own self-healing powers.
            </p>
        </div>
        <div className="dr-matin-img">
            <img src={DrMed} alt="dr. nicolas martin" />
        </div>
        <div className='ag-alone-container'>
            <p>     At the same time, the innovative manufacturing process of the new
                Cell Extracts by filtering out high structural proteins and unwanted particles will
                guaranty improved quality, effectiveness and safety of the products, so that even
                fewer side effects and allergic reactions can now be considered.

                We strongly believe that the "new LCT" is now the most comprehensive and
                effective method for an anti-ageing strategy by stimulating the body's energy reserves and vitality, including better memory function and generally enhancing daily
                performance through biological means.
                Also, during the last years, we have enjoyed to welcome many more international
                Patients, especially from China, at «The Four Seasons Clinic".
            </p>
        </div>
        <div className='ag-alone-container'>
            <img src={cover} alt="cover" />
        </div>
        <div className='ag-alone-container'>
            <p>
                Therefore. I had accepted an invitation from our Chinese cooperation-partners to
                lecture in Beijing and to be interviewed by Chinese TV about our revitalization therapy.
                Through the great acceptance in China I learned more about the similarity of the
                concepts of our *Live-Cel-Therapy" (LCT) and the over one thousand years old
                Traditional Chinese Medicine (TCM), since its pharmaceutical products also use organs and tissues from animals like tiger, bears, snow leopards and rhinos etc.

                That means, that TCM pharmaceuticals have a common basis with cell-extract
                preparations of our “nLCT" as far as its origin and indications are concerned.

                Therefore, it is worthwhile to state, that “nLCT" can be considered the most modern
                form of TCM pharmaceuticals.<br></br>

            </p>
            <ReadMore>
                During the last couple of years, the use of foetal organ extracts has also gained
                credence in scientific reports from medical centers around the world, stating that embryo cell extracts with their growth factors and with its stem cell potential are most beneficial in the repair of chronically diseased tissues and organs. These reports add even more validity, efficacy and more future perspective to our method.

                Therefore, our revitalization programme can also play an important role by
                strengthening the body's ability to detoxify and stimulate healing of diseased tissues and organs by enhancing self-healing capacities and mobilizing “sleeping" stem cells triggered by an Interferon induction mechanism, which our Institute was the first one to report more than 40 years ago.

                In this sense it is worth noting that the "nLCT" programme at the Four Seasons
                Clinic is an extremely significant step into the right direction to challenging ageing and to preserving good health and wellness using biological means.

                The main indications for our 6 days “nLCT" Revitalization-Programme, are
                musculoskeletal diseases such as osteoporosis, arthritis, of the joints and discopathy, decreased physical and mental vitality or stress-induced and age-related deterioration, depressions, menopause symptoms, circulatory disorders and chronic diseases in general.

                Treatments are primarily beneficial to be used as a preventive to these conditions
                through strengthening the body's natural healing power.

            </ReadMore>

        </div>

    </div >)
}

const SecondSection = () => {
    return (<div className='ag-container'>
        <div className='ag-sub-container'>
            <h1>Accomodation</h1>
        </div>
        <div className="hotel-logo" >
            <img src={hotelLogo} alt="bachmair-weissach" />

        </div>
        <video autoplay="autoplay" loop>
            <source src={hotelVdo} type="video/mp4" />
        </video>

        <div className='ag-alone-container'>
            <p>
                The building itself and its facilities are also completely refurbished, offering the
                diversity of a grand hotel with the private atmosphere of a boutique hotel. Discrete luxury meets modern elegance blended with Bavarian warmth and charm in all its facilities as well as the friendly services from the Hotel's staff.

                Last but not least, you have now the choice of four different restaurants,
                where the chefs personally create Bavarian specialities and will spoil your palate
                and taste-buds with exquisite international cuisine as per your preference. For
                connoisseurs of Japanese specialities we would like to recommend the in-house
                restaurant MIZU.
            </p>
            <p>
                Your well-being will be further enhanced by the amenities of the relaxing
                daytime bar, the stylish fire side lounge and the cosy foyers and lobby areas of the
                Hotel.
            </p>
            <p>
                As already announced 6 years ago you can also enjoy the gorgeous in-house
                spa- and wellness facilities during your visit at the new Japanese MIZU ONSEN
                SPA covering an area of 3000m². The SPA is offering different kinds of massages,
                professional skin analysis and individual facial treatments as well saunas, a
                steam bath, indoor and outdoor pools as well spa lounges for relaxation and
                contemplation.
            </p>
            <p>
                If you have any questions, please do not hesitate to contact us anytime. We
                would be very happy to welcome you and your friends at the Four Seasons Clinic
                very soon.

                With our best regards we remain

            </p>
        </div>
    </div >)

}


const AntiAging = props => {
    return (
        <div className='home-page'>
            <div className="home-container">
                <FisrtSection />
                <div className="space-between"></div>
                <SecondSection />
                <Footer />
            </div>
        </div >
    )

}

export default AntiAging

import Footer from '../footer'

// const FisrtSection = () => {
//     return (<div className='about-us-white-sec'>
//         <div className='about-us-first-sec-container'>
//             <img src={TheVerve} alt="the-verve-aboutus-page" />

//         </div>
//     </div>)
// }

// const SecondSection = () => {
//     return (<div className='about-us-white-sec'>
//         <div className='about-us-second-sec-container'>
//             <div className='about-us-box-content'>
//                 <h1>OUR VISION</h1>
//                 <p>Vertical Integration and State of the Art Internal Systems/Solutions<br />
//                     Strong Customer Relations Built on Trust<br />
//                     Solid Financial Position<br />
//                     Employee and Community Respect<br />
//                     Technology<br />
//                     Operational Excellence<br /></p>
//             </div>
//             <div className='about-us-box-content2'>
//                 <h1>OUR MISSION</h1>
//                 <p> &nbsp;&nbsp;Precision Medical Products and Therapy, Inc. is a medical device manufacturer committed to providing the highest level of excellence to our stakeholders through innovation,
//                     vertical integration and comprehensive value-added services to offer a complete single source solution.
//                     <br /> &nbsp;&nbsp;To inspire hope, and contribute to health and well-being by providing the best care to every patient through integrated clinical practice, education and research.</p>
//             </div>

//         </div>
//     </div >)

// }

// const ThirdSection = () => {
//     return (<div className="ceo-section">
//         <div>
//             <img src={ceoPic} alt="ceoPic" />
//             <h1>CEO</h1>
//             <p>Tientam Junjuajarn</p>
//         </div>
//         <div>
//             <h1>COO</h1>
//             <p>XXX XXXXX</p>
//         </div>
//         <div>
//             <h1>Manager</h1>
//             <p>XXX XXXXX</p>
//         </div>
//     </div>)
// }
const MedDevice = props => {
    return (
        <div></div>

    )

}

export default MedDevice
import footerBG from '../asset/footer_bg.png'
import TheVerveLogo from '../asset/The_Verve_Logo.png'
import './footer.css'
import IG from '../asset/icons8-instagram.svg'
import Email from '../asset/icons8-mail-30.png'

const LinkIn = "https://static.wixstatic.com/media/7528824071724d12a3e6c31eee0b40d4.png/v1/fill/w_50,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/7528824071724d12a3e6c31eee0b40d4.png"
const Facebook = "https://static.wixstatic.com/media/7528824071724d12a3e6c31eee0b40d4.png/v1/fill/w_50,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/7528824071724d12a3e6c31eee0b40d4.png"
const Tel = "https://static.wixstatic.com/media/292978_94a2111d1afa4707b6b2d00ec616d8cf~mv2.png/v1/fill/w_42,h_42,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Icon.png"

const Footer = () => {
    return (
        <div className="footer-sec" >
            <img src={footerBG} alt="footerBG" />
            <div className='footer-container'>
                <div className='footer-left-container'>
                    <img src={TheVerveLogo} alt="footerLogo"></img>

                    <div>
                        <h1>The Verve</h1>
                        <p>Burgstr 12  80331 . München</p>
                    </div>
                </div>
                <div className='footer-right-container'>
                    <div className="footer-right-content">
                        <img src={Tel} alt="tel-icon" />
                        <div> +49 15510186520 </div>

                    </div>
                    <div className="footer-right-content">
                        <img src={Email} alt="tel-icon" />
                        <div> polly@verve27.com </div>
                    </div>
                    <div className="footer-right-content">
                        <img src={Facebook} alt="tel-icon" />
                        <div> The Verve </div>
                    </div>
                    <div className="footer-right-content">
                        <img src={IG} alt="tel-icon" />
                        <div> verve_27 </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
export default Footer
